import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Image from '@components/Image';
import SharedLibs from 'wizarding-world-web-shared';
import Bugsnag from '@bugsnag/js';
import { HOGWARTS_HOUSES, QUIZ_DIFFICULTY_LEVEL } from '@constants/index';
import { GET_AGGREGATE_QUIZ_RESULT } from '@utils/graphQueries';
import { QUIZ_URL } from '@constants/routes';
import { trackClickEvent } from '@utils/analytics';
import useVerticalIndex from '@/src/hooks/useVerticalIndex';
import AnchorLink from '../../AnchorLink';
import s from './FeaturedQuizList.module.scss';
import star from './imgs/star.svg';

const propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  quizList: PropTypes.arrayOf(PropTypes.shape({})),
  analyticsParams: PropTypes.shape({
    rootContentfulId: PropTypes.string.isRequired,
    rootEntryTitle: PropTypes.string.isRequired,
  }),
};

const FeaturedQuizList = ({
  header = 'Quizzes',
  subheader = '',
  quizList = [],
  analyticsParams = {},
}) => {
  const { api: wizardingWorldApi } = SharedLibs.services;
  const { verticalIndex, componentRef } = useVerticalIndex();
  const {
    rootEntryTitle,
    rootContentTypeId: contentTypeId,
    rootContentfulId: contentfulId,
  } = analyticsParams;
  const [leadingHouses, setLeadingHouses] = useState([]);

  const getLeadingHouseForQuizzes = async () => {
    const quizSections = quizList.map((quiz) => {
      return quiz?.section?.filter((sec) => sec.contentTypeId === 'quiz')[0];
    });

    try {
      const promises = [];
      quizSections.forEach((quizSection) => {
        const promise = wizardingWorldApi().query({
          query: GET_AGGREGATE_QUIZ_RESULT,
          variables: { quizEntryId: quizSection.contentfulId },
        });
        promises.push(promise);
      });

      const results = await Promise.all(promises);
      const tempLeadingHouses = [];

      results.forEach(({ data }, index) => {
        let maxScore = -1;
        let leadingHouse = null;

        HOGWARTS_HOUSES.forEach((house) => {
          if (!data?.aggregatedQuizResult) return;

          const { correct, total } = data.aggregatedQuizResult[house];
          // istanbul ignore next
          const houseScore = (correct / total) * quizSections[index].quizQuestion.length || 0;

          if (houseScore > maxScore) {
            maxScore = houseScore;
            leadingHouse = house;
          }
        });
        tempLeadingHouses.push(leadingHouse);
      });
      setLeadingHouses(tempLeadingHouses);
    } catch (e) {
      /* istanbul ignore next */
      Bugsnag.notify(e);
    }
  };

  useEffect(() => {
    /* istanbul ignore else */
    if (quizList && quizList.length && !leadingHouses.length) {
      getLeadingHouseForQuizzes();
    }
  }, [quizList]);

  const renderStars = (displayTags) => {
    // Find the display tag that defines the quiz level
    let quizLevel = 'medium';
    if (displayTags) {
      displayTags.forEach((tag) => {
        if (tag?.name?.match(/^(easy|medium|difficult)-quiz$/)) {
          quizLevel = tag?.name?.split('-')?.[0];
        }
      });
    }

    // convert difficulty to stars
    const starArray = [];
    for (let i = 0; i < QUIZ_DIFFICULTY_LEVEL[quizLevel.toLowerCase()]; i += 1) {
      starArray.push(<Image imageSet={[{ src: star }]} alt="star" />);
    }

    return starArray.map((starImage) => {
      return (
        <div key={`starImage${Math.random()}`} className={s.star}>
          {starImage}
        </div>
      );
    });
  };

  return (
    <div className={s.root} ref={componentRef}>
      <h2 className={s.header}>{header}</h2>
      <div className={s.subHeader}>{subheader}</div>
      <AnchorLink
        href={QUIZ_URL}
        className={s.seeAllQuizzes}
        onClick={() => {
          trackClickEvent(
            {
              destination_url: QUIZ_URL,
              label: 'SEE ALL QUIZZES',
              location: 'Featured Quiz List',
              vertical_index: verticalIndex,
              horizontal_index: -1,
              content_name: rootEntryTitle,
            },
            { contentfulId, contentTypeId },
          );
        }}
      >
        <span className={s.desktopOnly}>SEE </span>
        ALL QUIZZES
      </AnchorLink>
      <div className={s.quizzes} data-testid="quizListContainer">
        {quizList &&
          quizList.map((quiz, index) => {
            const quizSection = quiz?.section?.filter((sec) => sec.contentTypeId === 'quiz')[0];
            if (!quizSection) return null;

            return (
              <AnchorLink
                className={s.quiz}
                href={`${QUIZ_URL}/${quiz.externalId}`}
                key={`${quiz.contentfulId}${Math.random()}`}
                onClick={() => {
                  trackClickEvent(
                    {
                      destination_url: `${QUIZ_URL}/${quiz.externalId}`,
                      label: quizSection.displayTitle,
                      location: 'Featured Quiz List',
                      vertical_index: verticalIndex,
                      horizontal_index: index,
                      content_name: quizSection.displayTitle,
                    },
                    quiz,
                  );
                }}
              >
                <div className={s.coverImageContainer}>
                  <Image
                    cover
                    alt={quizSection.displayTitle}
                    imageSet={[
                      {
                        src: quizSection?.mainImage?.image?.file?.url,
                      },
                    ]}
                  />
                  <div className={s.quizInfo}>
                    <div>
                      <h3 className={s.quizTitle}>{quizSection.displayTitle}</h3>
                      <div>
                        <span className={s.stars}>
                          Level&nbsp;&nbsp;
                          {renderStars(quizSection.displayTag)}
                        </span>
                        <span className={s.divider}>|</span>
                        <span className={s.questionCount}>
                          {`${quizSection.quizQuestion?.length} Questions`}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={s.innerBorder} />
                  {leadingHouses && leadingHouses[index] && (
                    <div className={[s.leader, s[leadingHouses[index].toLowerCase()]].join(' ')}>
                      LEADS
                    </div>
                  )}
                </div>
              </AnchorLink>
            );
          })}
      </div>
    </div>
  );
};

FeaturedQuizList.propTypes = propTypes;
export default FeaturedQuizList;
