import React from 'react';
import PropTypes from 'prop-types';
import ButtonNew from '@components/ButtonNew';
import AnchorLink from '@components/AnchorLink';
import { trackClickEvent } from '@utils/analytics';
import useVerticalIndex from '@/src/hooks/useVerticalIndex';
import s from './FullWidthBanner.module.scss';

const propTypes = {
  image: PropTypes.shape({
    image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
  orientation: PropTypes.oneOf(['left', 'right']),
  header: PropTypes.string,
  subheader: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaURL: PropTypes.string,
  contentfulId: PropTypes.string,
  contentTypeId: PropTypes.string,
  tags: PropTypes.array,
};

const FullWidthBanner = ({
  image = {},
  orientation = 'left',
  header = '',
  subheader = '',
  ctaLabel = '',
  ctaURL = '',
  contentfulId = '',
  contentTypeId = '',
  tags = [],
}) => {
  const { verticalIndex, componentRef } = useVerticalIndex();
  return (
    <div className={[s.root, s[orientation]].join(' ')} ref={componentRef}>
      <div className={s.image}>
        {image?.image && <img alt={header} src={image?.image?.file?.url} />}
      </div>
      <div className={s.cta}>
        <h2 className={s.header}>{header}</h2>
        <p className={s.subheader}>{subheader}</p>
        {ctaLabel && ctaLabel && (
          <AnchorLink
            href={ctaURL}
            onClick={() => {
              trackClickEvent(
                {
                  destination_url: ctaURL,
                  label: ctaLabel,
                  location: 'Full-Width Banner',
                  vertical_index: verticalIndex,
                  horizontal_index: 0,
                  content_name: header,
                },
                { tags, contentfulId, contentTypeId },
              );
            }}
          >
            <ButtonNew label={ctaLabel} level="secondary" theme="modern" />
          </AnchorLink>
        )}
      </div>
    </div>
  );
};

FullWidthBanner.propTypes = propTypes;
export default FullWidthBanner;
